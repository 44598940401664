import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeStatic, html } from 'lit/static-html.js';
import { ElementTagName, validElementTagNames } from './utils/HTMLTypes';
import { TextAlign, FontWeigth } from './utils/CommonTypes';
import { spread, fromOptionalConverter } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';
import { assertTagNameIsAllowed } from './utils/AssertHelpers';

export type HeadingLevel = '1' | '2' | '3' | '4' | '5' | '6';

@customElement('mono-heading')
export class MonoHeadingComp extends LitElement {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: String, reflect: true }) as: ElementTagName = 'h1';

  @property({
    type: String,
    reflect: true,
  })
  level: HeadingLevel = '1';

  @property({
    type: String,
    reflect: true,
  })
  weight: FontWeigth = 'medium';

  @property({
    type: String,
    reflect: true,
    attribute: 'align-text',
    converter: fromOptionalConverter,
  })
  alignText?: TextAlign;

  @property({ type: Boolean, reflect: true }) truncate: boolean = false;

  private __computTextSizeForLevel() {
    switch (this.level) {
      case '1':
        return 'text-heading-1 lg:text-heading-1-big';
      case '2':
        return 'text-heading-2 lg:text-heading-2-big';
      case '3':
        return 'text-heading-3 lg:text-heading-3-big';
      case '4':
        return 'text-heading-4 lg:text-heading-4-big';
      case '5':
        return 'text-heading-5 lg:text-heading-5-big';
      case '6':
        return 'text-heading-6 lg:text-heading-6-big';
      default:
        return 'base';
    }
  }

  /* eslint-disable lit/binding-positions,lit/no-invalid-html  */

  render() {
    assertTagNameIsAllowed(
      this.as,
      validElementTagNames as unknown as string[],
    );

    const tag = unsafeStatic(this.as);

    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'level',
        'weight',
        'align-text',
        'truncate',
      ]);

    return html`
      <${tag}
        class=${this.__stylesController.tw(
          `${this.__computTextSizeForLevel()} font-feature tracking-heading`,
          this.weight && `font-${this.weight}`,
          this.alignText && `text-${this.alignText}`,
        )}
        ...=${spread(attributesToSpread)}
      >
          <div class=${this.__stylesController.tw(this.truncate && 'truncate')}>
            <slot></slot>
          </div>
      </${tag}>
    `;
  }

  /* eslint-enable lit/binding-positions,lit/no-invalid-html  */
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-heading': MonoHeadingComp;
  }
}
