import { css as litCss, LitElement } from 'lit';
import {
  customElement,
  property,
  query,
  queryAll,
  state,
} from 'lit/decorators.js';
import { html, nothing } from 'lit/html.js';
import { css } from 'twind/css';
import { createUUID } from './utils/UniqueId';
import { MonoTextComp } from './MonoTextComp';
import { fromOptionalConverter, spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { visuallyHidden } from './utils/AccessiblityHelper';
import { InputTone, RoundedCorners } from './utils/CommonTypes';
import { TailwindStylesController } from './utils/TailwindStylesController';

export type ButtonGroupOption = {
  selected?: boolean;
  value: string;
  text: string;
};

@customElement('mono-button-group')
export class MonoButtonGroupComp extends LitElement {
  static styles = litCss`
    ::slotted(*:first-child) {
      margin: 0 !important;
    }
  `;

  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: String, reflect: true })
  value: string = '';

  @property({ type: Array, reflect: true }) options: ButtonGroupOption[] = [];

  @property({ type: String, reflect: true }) id: string = createUUID();

  @property({ type: String, reflect: true }) name: string = '';

  @property({ type: String, reflect: true, converter: fromOptionalConverter })
  error?: string;

  @property({ type: Boolean, reflect: true }) disabled: boolean = false;

  @property({ type: Boolean, reflect: true }) required: boolean = false;

  @property({ type: String, reflect: true }) tone: InputTone = 'highlight';

  @property({ type: String, reflect: true }) corners: RoundedCorners = 'none';

  @state()
  focusedValue: string = '';

  @query('legend', true)
  __legendEl!: HTMLLabelElement;

  @queryAll('input')
  __inputsEl!: Array<HTMLInputElement>;

  @queryAll('label')
  __labelsEl!: Array<HTMLLabelElement>;

  @query('[id*="-errors"]', true)
  __errorEl!: MonoTextComp;

  private __onInput(_event: Event, value: string) {
    this.value = value;
  }

  private __onChange(event: Event, value: string) {
    event.preventDefault();
    event.stopPropagation();

    this.value = value;

    const changeEvent = new CustomEvent('change', {
      detail: { value: this.value },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(changeEvent);
  }

  private __onFocus(_event: FocusEvent, value: string) {
    this.focusedValue = value;
  }

  private __onBlur(_event: FocusEvent, value: string) {
    if (this.focusedValue === value) {
      this.focusedValue = '';
    }
  }

  private __hasError() {
    return this.error && this.error.length > 0;
  }

  private __renderError(ariaDescribedBy: string) {
    if (this.__hasError()) {
      return html`
        <mono-text as="p" size="sm" tone="alert" id=${ariaDescribedBy}>
          ${this.error}
        </mono-text>
      `;
    }

    return nothing;
  }

  // eslint-disable-next-line class-methods-use-this
  private __computeButtonStyle(selected: boolean) {
    if (!selected) {
      return 'bg-neutral-3 text-neutral-5';
    }

    switch (this.tone) {
      case 'primary': {
        return 'bg-primary text-white';
      }
      case 'accent': {
        return 'bg-accent text-white';
      }
      case 'dark': {
        return 'bg-neutral-5 text-white';
      }
      case 'white': {
        return 'bg-white text-neutral-5';
      }
      default:
        return 'bg-highlight-2 text-white';
    }
  }

  private __computeCornersForIndex(index: number) {
    if (this.corners === 'none') {
      return null;
    }

    if (index === 0) {
      return 'rounded-l';
    }

    if (index === this.options.length - 1) {
      return 'rounded-r';
    }

    return null;
  }

  private __focus(_event: MouseEvent) {
    const selectedIndex = Math.max(
      0,
      this.options.map((option) => option.value).indexOf(this.value),
    );
    if (this.__inputsEl.length >= selectedIndex) {
      this.__inputsEl[selectedIndex].focus();
    }
  }

  render() {
    const ariaDescribedBy = this.__hasError() ? `${this.id}-errors` : '';

    const focusVisible = css({
      'input&:focus-visible + label': {
        outline: '2px dotted black',
        outlineOffset: '2px',
      },
    });

    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'value',
        'id',
        'name',
        'options',
        'error',
        'disabled',
        'required',
        'tone',
        'corners',
      ]);

    /* eslint-disable lit-a11y/click-events-have-key-events */

    return html`
      <div
        class=${this.__stylesController.tw('flex flex-col space-y-2.5')}
        ...=${spread(attributesToSpread)}
      >
        <fieldset
          class=${this.__stylesController.tw('flex flex-col space-y-2.5')}
        >
          <div>
            <legend>
              <slot name="label" @click=${this.__focus}></slot>
              <slot name="help"></slot>
            </legend>
          </div>
          <div
            id="focus-container"
            class=${this.__stylesController.tw(
              this.corners === 'rounded' && 'rounded',
              this.__hasError() && 'border-2 border-alert',
            )}
          >
            <mono-columns space="3xs">
              ${this.options.map(({ selected, value, text }, index) => {
                const id = `${this.id}-option-${index}`;

                return html`
                  <mono-column>
                    <input
                      id=${id}
                      name=${this.name}
                      type="radio"
                      .value=${value}
                      class=${this.__stylesController.tw(
                        visuallyHidden,
                        focusVisible,
                      )}
                      ?checked=${this.value.length > 0
                        ? this.value === value
                        : selected}
                      ?disabled=${this.disabled}
                      ?required=${this.required}
                      @input=${(event: Event) => this.__onInput(event, value)}
                      @change=${(event: Event) => this.__onChange(event, value)}
                      @focus=${(event: FocusEvent) =>
                        this.__onFocus(event, value)}
                      @blur=${(event: FocusEvent) =>
                        this.__onBlur(event, value)}
                    />
                    <label
                      for=${id}
                      class=${this.__stylesController.tw(
                        'flex flex-row items-center justify-center py-2.5 px-3 w-full transition duration-300 text-lg font-feature font-medium',
                        this.__computeCornersForIndex(index),
                        this.__computeButtonStyle(
                          this.value.length > 0
                            ? this.value === value
                            : selected || false,
                        ),
                        this.disabled &&
                          'cursor-not-allowed pointer-events-none opacity-60',
                      )}
                    >
                      ${text}
                    </label>
                  </mono-column>
                `;
              })}
            </mono-columns>
          </div>
        </fieldset>
        ${this.__renderError(ariaDescribedBy)}
      </div>
    `;

    /* eslint-enable lit-a11y/click-events-have-key-events */
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-button-group': MonoButtonGroupComp;
  }
}
