import { LitElement } from 'lit';
import { nothing } from 'lit/html.js';
import { customElement, property } from 'lit/decorators.js';
import { unsafeStatic, html } from 'lit/static-html.js';
import { ElementTagName, validElementTagNames } from './utils/HTMLTypes';
import { spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';
import { assertTagNameIsAllowed } from './utils/AssertHelpers';

export type IconName =
  | 'menu'
  | 'phone'
  | 'user'
  | 'pencil-alt'
  | 'help-bubble'
  | 'ambulance'
  | 'tick'
  | 'info';

@customElement('mono-icon')
export class MonoIconComp extends LitElement {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: String, reflect: true }) as: ElementTagName = 'div';

  @property({
    type: String,
    reflect: true,
    attribute: 'icon-name',
  })
  iconName: IconName = 'menu';

  @property({ type: Number, reflect: true, attribute: 'size' })
  size: number = 6;

  @property({ type: Number, reflect: true, attribute: 'stroke-width' })
  strokeWidth: number = 1;

  private __getSvgIcon() {
    switch (this.iconName) {
      case 'menu':
        return html`
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=${this.strokeWidth}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        `;
      case 'phone':
        return html`
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=${this.strokeWidth}
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
          </svg>
        `;
      case 'user':
        return html`
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=${this.strokeWidth}
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
        `;
      case 'pencil-alt':
        return html`
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=${this.strokeWidth}
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        `;

      case 'help-bubble':
        return html`
          <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 118 0a8 8 0 010 16zm-.751-4.518h1.53V13H7.25v-1.518zM5 6.283c-.01-.465.057-.898.2-1.3.142-.4.347-.75.614-1.046.266-.296.593-.527.979-.69A3.283 3.283 0 018.09 3c.432 0 .825.062 1.18.185.353.123.659.3.916.533.258.233.458.518.6.855.143.338.214.725.214 1.163a2.572 2.572 0 01-.372 1.368 3.17 3.17 0 01-.38.506 13.58 13.58 0 01-.434.452 15.5 15.5 0 00-.434.417 3.59 3.59 0 00-.387.451 2.17 2.17 0 00-.275.52 1.83 1.83 0 00-.104.636v.534H7.442v-.643c.018-.383.09-.71.214-.978a3.09 3.09 0 01.455-.719c.179-.21.368-.403.565-.581.198-.178.382-.365.552-.56a2.49 2.49 0 00.407-.65c.1-.238.142-.525.124-.862-.037-.502-.2-.894-.49-1.177-.29-.283-.682-.424-1.179-.424-.33 0-.616.06-.855.178a1.76 1.76 0 00-.6.479c-.16.2-.278.437-.352.711-.073.274-.11.57-.11.89H5.001z"
              fill="currentColor"
            />
          </svg>
        `;
      case 'ambulance':
        return html`
          <svg
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-stroke icon-md"
          >
            <g
              stroke="currentColor"
              fill="none"
              fill-rule="evenodd"
              stroke-width=${this.strokeWidth}
            >
              <path
                d="M22 45.5a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zm30 0a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.578 46H6.67C5.196 46 4 44.816 4 43.355V35.66c0-2.132 1.556-3.951 3.678-4.301l9.567-2.201a4.45 4.45 0 002.812-1.684l4.193-7.024A3.708 3.708 0 0127.196 19h29.163C57.818 19 59 20.172 59 21.616v21.373C59 44.653 57.64 46 55.96 46h-3.962"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M42 19v-2.491A2.512 2.512 0 0144.5 14c1.375 0 2.5 1.13 2.5 2.509V19"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path stroke-linecap="square" d="M23 47h18-19.028" />
            </g>
          </svg>
        `;
      case 'tick':
        return html`
          <svg
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-stroke icon-lg"
          >
            <g
              stroke="currentColor"
              fill="none"
              fill-rule="evenodd"
              stroke-linejoin="round"
              stroke-width=${this.strokeWidth}
            >
              <path
                d="M52 32c0 11.044-8.956 20-20 20-11.05 0-20-8.956-20-20s8.95-20 20-20c11.044 0 20 8.956 20 20z"
              />
              <path
                stroke-linecap="round"
                d="M41.09 26.546L29.424 39.272 22.91 32.91"
              />
            </g>
          </svg>
        `;
      case 'info':
        return html`
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=${this.strokeWidth}
              d="M14.25 16.5h-.75c-.398 0-.78-.158-1.06-.44A1.494 1.494 0 0112 15v-3.75a.747.747 0 00-.22-.53.747.747 0 00-.53-.22h-.75m1.125-3.75a.374.374 0 100 .748.374.374 0 000-.748z"
            />
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width=${this.strokeWidth}
              d="M12 23.25c6.213 0 11.25-5.037 11.25-11.25S18.213.75 12 .75.75 5.787.75 12 5.787 23.25 12 23.25z"
            />
          </svg>
        `;
      default:
        return nothing;
    }
  }

  /* eslint-disable lit/binding-positions,lit/no-invalid-html  */

  render() {
    assertTagNameIsAllowed(
      this.as,
      validElementTagNames as unknown as string[],
    );

    const tag = unsafeStatic(this.as);

    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'icon-name',
        'size',
        'stroke-width',
      ]);

    return html`
      <${tag}
        class=${this.__stylesController.tw(
          'flex flex-col',
          `w-${this.size} h-${this.size}`,
        )}
        ...=${spread(attributesToSpread)}
      >
        ${this.__getSvgIcon()}
      </${tag}>
    `;
  }

  /* eslint-enable lit/binding-positions,lit/no-invalid-html  */
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-icon': MonoIconComp;
  }
}
