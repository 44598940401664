import { css as litCss, LitElement, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { html, nothing } from 'lit/html.js';
import { Hideable } from './utils/CommonInterfaces';
import { RoundedCorners } from './utils/CommonTypes';
import { spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';

export type GlobalAlertType = 'info';

@customElement('mono-global-alert')
export class MonoGlobalAlertComp extends LitElement implements Hideable {
  static styles = litCss`
    ::slotted(*:first-child) {
      margin: 0 !important;
    }
  `;

  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({
    type: String,
    reflect: true,
  })
  type: GlobalAlertType = 'info';

  @property({ type: Boolean, reflect: true }) hidden: boolean = false;

  @property({ type: String, reflect: true }) corners: RoundedCorners = 'none';

  __getIcon() {
    switch (this.type) {
      case 'info':
        return html`<mono-icon
          icon-name="info"
          size="6"
          stroke-width="1.5"
          role="img"
          aria-label="info"
        ></mono-icon>`;
      default:
        return nothing;
    }
  }

  private __getSlotNamed(name: string) {
    return Array.from(this.children).find((child) => child.slot === name);
  }

  isHidden() {
    return this.hidden;
  }

  updated(changed: PropertyValues<this>): void {
    if (changed.has('hidden')) {
      const event = new CustomEvent('mono-hideable', {
        detail: { hidden: this.hidden },
        bubbles: true,
        composed: true,
      });
      this.dispatchEvent(event);
    }
  }

  private __renderCTASlot() {
    if (this.__getSlotNamed('cta')) {
      return html`
        <div class=${this.__stylesController.tw('flex')}>
          <slot name="cta"></slot>
        </div>
      `;
    }

    return nothing;
  }

  render() {
    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'type',
        'hidden',
        'corners',
      ]);

    return html`
      <div
        class=${this.__stylesController.tw(
          'flex bg-neutral-3',
          this.corners === 'rounded' && 'rounded',
          this.hidden && 'hidden',
        )}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        ...=${spread(attributesToSpread)}
      >
        <div class=${this.__stylesController.tw('flex p-7')}>
          <div class=${this.__stylesController.tw('text-neutral-5')}>
            <slot name="icon"> ${this.__getIcon()} </slot>
          </div>
          <div
            class=${this.__stylesController.tw('items-center ml-3 space-y-4')}
          >
            <mono-text size="base" tone="neutral-5">
              <slot></slot>
            </mono-text>
            ${this.__renderCTASlot()}
          </div>
        </div>
      </div>
    `;
  }

  /* eslint-enable lit/binding-positions,lit/no-invalid-html  */
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-global-alert': MonoGlobalAlertComp;
  }
}
