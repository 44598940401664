import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { html } from 'lit/html.js';
import { spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { AnimationController } from './utils/AnimationController';
import { Tone } from './utils/CommonTypes';
import { TailwindStylesController } from './utils/TailwindStylesController';

export type SpinnerSize = 'base' | 'md' | 'lg' | 'xl' | '2xl';

@customElement('mono-spinner')
export class MonoSpinnerComp extends LitElement {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  private __animationController: AnimationController = new AnimationController(
    this,
  );

  @property({ type: String, reflect: true }) size: SpinnerSize = 'base';

  @property({
    type: String,
    reflect: true,
  })
  tone?: Tone;

  private __computSize() {
    switch (this.size) {
      case 'md':
        return 'w-5 h-5';
      case 'lg':
        return 'w-10 h-10';
      case 'xl':
        return 'w-16 h-16';
      case '2xl':
        return 'w-20 h-20';
      default:
        return 'w-4 h-4';
    }
  }

  render() {
    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'size',
        'tone',
        'aria-live',
        'aria-busy',
      ]);

    return html`
      <svg
        class=${this.__stylesController.tw(
          !this.__animationController.shouldDisableAnimation && 'animate-spin',
          this.__computSize(),
          this.tone && `text-${this.tone}`,
        )}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        aria-busy="true"
        aria-live="polite"
        ...=${spread(attributesToSpread)}
      >
        <circle
          class=${this.__stylesController.tw('opacity-25')}
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class=${this.__stylesController.tw('opacity-75')}
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-spinner': MonoSpinnerComp;
  }
}
