/* eslint-disable lit-a11y/aria-activedescendant-has-tabindex */
/* eslint-disable no-return-assign */
/* eslint-disable lit-a11y/mouse-events-have-key-events */
import { css, LitElement, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { html } from 'lit/html.js';
import { MonoTextFieldComp } from './MonoTextFieldComp';
import { fromOptionalConverter, spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';

const ARROW_DOWN_KEY = 'ArrowDown';
const ARROW_UP_KEY = 'ArrowUp';
const ENTER_KEY = 'Enter';
const ESCAPE_KEY = 'Escape';
const TAB_KEY = 'Tab';

@customElement('mono-autosuggest')
export class MonoAutoSuggestComp extends LitElement {
  static styles = css`
    ::slotted(*:first-child) {
      margin: 0 !important;
    }
  `;

  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: String, reflect: true })
  value: string = '';

  @property({ type: String, reflect: true, converter: fromOptionalConverter })
  error?: string;

  @property({ type: Array, reflect: true }) suggestions: Array<string> = [];

  @property({ type: Boolean, reflect: true }) disabled: boolean = false;

  @property({ type: Number, reflect: true, attribute: 'cursor-index' })
  cursorIndex = 0;

  @property({ type: Boolean, reflect: true, attribute: 'show-suggestion' })
  showSuggestion = false;

  @query('mono-textfield', true)
  __monoTextfield!: MonoTextFieldComp;

  @query('#suggestions', true)
  __suggestionList!: HTMLButtonElement;

  private isOnScreen = () => {
    const listBoxXY = this.__suggestionList.getBoundingClientRect();
    if (listBoxXY.top + listBoxXY.height > window.innerHeight) {
      this.__suggestionList.scrollIntoView(false);
    }
  };

  private handleChange(event: CustomEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.value = event.detail.value;

    const changeEvent = new CustomEvent('change', {
      detail: { value: this.value },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(changeEvent);
  }

  private handleInput(event: CustomEvent) {
    this.value = event.detail.value;
  }

  private handleKeydown = (event: KeyboardEvent) => {
    if (event.key === ARROW_DOWN_KEY || event.key === ARROW_UP_KEY) {
      this.isOnScreen();
    }
    if (
      event.key === ARROW_DOWN_KEY &&
      this.cursorIndex < this.suggestions.length
    ) {
      this.showSuggestion = true;
      this.cursorIndex += 1;
      event.preventDefault();
    } else if (event.key === ARROW_UP_KEY && this.cursorIndex > 0) {
      this.cursorIndex -= 1;
      event.preventDefault();
    } else if (event.key === ENTER_KEY) {
      if (this.cursorIndex > 0) {
        this.optionSelected(this.cursorIndex - 1);
        if (this.showSuggestion) {
          event.preventDefault();
        }
      }
      this.showSuggestion = false;
    }
    if (event.key === ESCAPE_KEY || event.key === TAB_KEY) {
      this.showSuggestion = false;
    }
  };

  private onOptionClick = (event: Event, index: number) => {
    event.preventDefault();
    this.optionSelected(index);
  };

  private optionSelected = async (index: number) => {
    const newValue = this.suggestions[index];
    this.value = newValue;
    this.__monoTextfield.value = newValue;
    this.showSuggestion = false;

    if (this.__monoTextfield.__inputEl) this.__monoTextfield.__inputEl.focus();

    const inputEvent = new CustomEvent('input', {
      detail: { value: this.value },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(inputEvent);
  };

  private handleBlur = (event: Event) => {
    event.preventDefault();
    if (!this.cursorIndex) {
      this.showSuggestion = false;
    }
  };

  private hasError(): string {
    return (this.suggestions.length === 0 || !this.showSuggestion) && this.error
      ? this.error
      : '';
  }

  private renderSuggestions() {
    return this.showSuggestion && this.suggestions.length > 0
      ? html` ${this.suggestions.map(
          (suggestion, index) =>
            // eslint-disable-next-line lit-a11y/click-events-have-key-events
            html`<li
              class=${this.__stylesController.tw(
                'p-2',
                this.cursorIndex === index + 1 && 'bg-neutral-3 cursor-pointer',
              )}
              id=${`suggestion-${index}`}
              role="option"
              tabindex="-1"
              aria-selected=${this.cursorIndex === index + 1}
              @click=${(e: Event) => this.onOptionClick(e, index)}
              @mouseover=${() => (this.cursorIndex = index + 1)}
              @mouseout=${() => (this.cursorIndex = 0)}
            >
              ${suggestion}
            </li>`,
        )}`
      : nothing;
  }

  render() {
    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'value',
        'error',
        'disabled',
        'showSuggestion',
        'suggestions',
        'cursorIndex',
      ]);

    return html`
      <div class=${this.__stylesController.tw('flex flex-col space-y-2')}>
        <mono-textfield
          value=${this.value}
          error=${this.hasError()}
          ?disabled=${this.disabled}
          aria-owns="suggestions"
          aria-expanded=${this.showSuggestion && this.suggestions.length > 0}
          aria-autocomplete="both"
          aria-activedescendant=${this.cursorIndex > 0 &&
          this.cursorIndex <= this.suggestions.length
            ? `suggestion-${this.cursorIndex - 1}`
            : ''}
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
          @input=${this.handleInput}
          @change=${this.handleChange}
          @keydown=${this.handleKeydown}
          @blur=${this.handleBlur}
          ...=${spread(attributesToSpread)}
        >
          <slot name="label" slot="label"></slot>
          <slot name="help" slot="help"></slot>
        </mono-textfield>
        <ul
          class=${this.__stylesController.tw(
            this.showSuggestion &&
              this.suggestions.length > 0 &&
              'pt-2 pb-2 border-2 border-neutral-3 rounded',
          )}
          id="suggestions"
          role="listbox"
        >
          ${this.renderSuggestions()}
        </ul>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-autosuggest': MonoAutoSuggestComp;
  }
}
