/* eslint-disable no-plusplus */
// original code: https://github.com/marcelodosreis/node-masker

const DIGIT = '9';
const ALPHA = 'A';
const ALPHANUM = 'S';

type OptionPattern = {
  pattern: string;
  placeholder: string;
};

function addPlaceholder(
  output: string[],
  index: number,
  placeholder: string,
): string[] {
  for (let newIndex = index; newIndex < output.length; newIndex++) {
    if (
      output[newIndex] === DIGIT ||
      output[newIndex] === ALPHA ||
      output[newIndex] === ALPHANUM
    ) {
      // eslint-disable-next-line no-param-reassign
      output[newIndex] = placeholder;
    }
  }
  return output;
}

function toPattern(
  value: number | string,
  optionPattern: string | Function | OptionPattern,
): string {
  const pattern =
    typeof optionPattern === 'object' ? optionPattern.pattern : optionPattern;

  if (typeof pattern === 'function') return pattern(value);

  const patternChars = pattern.replace(/\W/g, '');
  const output = pattern.split('');
  const values = value.toString().replace(/\W/g, '');
  const charsValues = values.replace(/\W/g, '');
  const placeholder =
    typeof optionPattern === 'object' ? optionPattern.placeholder : undefined;
  let charCounter = 0;
  let index;

  const outputLength = output.length;
  for (index = 0; index < outputLength; index++) {
    // Reached the end of input
    if (charCounter >= values.length) {
      if (patternChars.length === charsValues.length) {
        return output.join('');
      }
      if (
        placeholder !== undefined &&
        patternChars.length > charsValues.length
      ) {
        return addPlaceholder(output, index, placeholder).join('');
      }
      break;
    } else if (
      (output[index] === DIGIT && values[charCounter].match(/[0-9]/)) ||
      (output[index] === ALPHA && values[charCounter].match(/[a-zA-Z]/)) ||
      (output[index] === ALPHANUM && values[charCounter].match(/[0-9a-zA-Z]/))
    ) {
      output[index] = values[charCounter++];
    } else if (
      output[index] === DIGIT ||
      output[index] === ALPHA ||
      output[index] === ALPHANUM
    ) {
      if (placeholder !== undefined) {
        return addPlaceholder(output, index, placeholder).join('');
      }
      return output.slice(0, index).join('');

      // exact match for a non-magic character
    } else if (output[index] === values[charCounter]) {
      charCounter++;
    }
  }
  return output.join('').substr(0, index);
}

function masker(value: string, pattern: string | Function, options: any) {
  return toPattern(value, { pattern, ...options });
}

export function mask(
  value: string | number,
  pattern: string | Function,
  options?: any,
) {
  return masker(String(value), pattern || '', options);
}
