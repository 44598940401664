import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { html } from 'lit/html.js';
import { spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';
import { visuallyHidden } from './utils/AccessiblityHelper';
import { Hideable } from './utils/CommonInterfaces';

@customElement('mono-hidden-visually')
export class MonoHiddenVisuallyComp extends LitElement implements Hideable {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  // eslint-disable-next-line class-methods-use-this
  isHidden() {
    return true;
  }

  render() {
    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
      ]);

    return html`
      <div
        class=${this.__stylesController.tw(visuallyHidden)}
        ...=${spread(attributesToSpread)}
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-hidden-visually': MonoHiddenVisuallyComp;
  }
}
