import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeStatic, html } from 'lit/static-html.js';
import { Token } from 'twind';
import { assertTagNameIsAllowed } from './utils/AssertHelpers';
import { ElementTagName, validElementTagNames } from './utils/HTMLTypes';
import { spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';
import { UniqueSlotController } from './utils/UniqueSlotController';

@customElement('mono-box')
export class MonoBoxComp extends LitElement {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  private __uniqueSlotController: UniqueSlotController =
    new UniqueSlotController(this);

  @property({ type: String, reflect: true }) as: ElementTagName = 'div';

  @property({ type: String, reflect: true }) clsx: string = '';

  @property({ type: Boolean, reflect: true }) grow: boolean = false;

  /* eslint-disable lit/binding-positions,lit/no-invalid-html  */

  render() {
    assertTagNameIsAllowed(
      this.as,
      validElementTagNames as unknown as string[],
    );

    const tag = unsafeStatic(this.as);

    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'class',
        'slot',
        'clsx',
        'grow',
      ]);

    return html`
      <${tag} class=${this.__stylesController.tw(
      'flex flex-col',
      this.clsx as Token,
    )} ...=${spread(attributesToSpread)}>
       ${Array.from(this.children).map(
         (_, index) =>
           html` <div
             class=${this.__stylesController.tw(this.grow && 'flex-grow')}
           >
             <slot
               name=${this.__uniqueSlotController.getSlotIdentifier(index)}
               @slotchange=${(event: Event) =>
                 this.__uniqueSlotController.onSlotChange(event)}
             ></slot>
           </div>`,
       )}
      </${tag}>
    `;
  }

  /* eslint-enable lit/binding-positions,lit/no-invalid-html  */
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-box': MonoBoxComp;
  }
}
