import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { html } from 'lit/html.js';
import {
  AlignX,
  AlignY,
  ResponsiveBreakpoint,
  Space,
} from './utils/CommonTypes';
import { ColumnWidth, MonoColumnComp } from './MonoColumnComp';
import { fromOptionalConverter, spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { UniqueSlotController } from './utils/UniqueSlotController';
import { TailwindStylesController } from './utils/TailwindStylesController';

@customElement('mono-columns')
export class MonoColumnsComp extends LitElement {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  private __uniqueSlotController: UniqueSlotController =
    new UniqueSlotController(this);

  @property({ type: String, reflect: true }) space: Space = 'none';

  @property({ type: String, reflect: true, attribute: 'align-x' })
  alignX?: AlignX;

  @property({
    type: String,
    reflect: true,
    attribute: 'align-y',
    converter: fromOptionalConverter,
  })
  alignY?: AlignY;

  @property({
    type: String,
    reflect: true,
    attribute: 'collapse-below',
    converter: fromOptionalConverter,
  })
  collapseBelow?: ResponsiveBreakpoint;

  @property({
    type: Boolean,
    reflect: true,
    attribute: 'columns-have-container',
  })
  columnsHaveContainer?: boolean;

  private __collapsableSpaceX(value: string) {
    if (this.collapseBelow) {
      const splitted = value.split(' ');
      const swapped = [
        splitted[0].replace('x', 'y'),
        splitted[1].replace('y', 'x'),
      ].join(' ');

      return `${swapped} ${this.collapseBelow}:(${value})`;
    }

    return value;
  }

  private __computeSpaceX() {
    switch (this.space) {
      case 'none':
        return 'space-y-0 space-x-0';
      case '3xs':
        return this.__collapsableSpaceX('space-x-0.5 space-y-0');
      case '2xs':
        return this.__collapsableSpaceX('space-x-1 space-y-0');
      case 'xs':
        return this.__collapsableSpaceX('space-x-2 space-y-0');
      case 'sm':
        return this.__collapsableSpaceX('space-x-3 space-y-0');
      case 'md':
        return this.__collapsableSpaceX('space-x-4 space-y-0');
      case 'gutter':
        return this.__collapsableSpaceX('space-x-5 space-y-0');
      case 'lg':
        return this.__collapsableSpaceX('space-x-6 space-y-0');
      case 'xl':
        return this.__collapsableSpaceX('space-x-8 space-y-0');
      case '2xl':
        return this.__collapsableSpaceX('space-x-16 space-y-0');
      default:
        return null;
    }
  }

  private __computeAlignX() {
    switch (this.alignX) {
      case 'left':
        return 'justify-start';
      case 'center':
        return 'justify-center';
      case 'right':
        return 'justify-end';
      default:
        return null;
    }
  }

  private __computeAlignY() {
    switch (this.alignY) {
      case 'top':
        return 'items-start';
      case 'bottom':
        return 'items-end';
      case 'center':
        return 'items-center';
      default:
        return null;
    }
  }

  private __collapsableWidth(value: string) {
    return this.collapseBelow ? `w-full ${this.collapseBelow}:${value}` : value;
  }

  private __computeColumnWidth(width?: ColumnWidth) {
    if (this.alignX === 'stretch') {
      return 'w-full';
    }

    if (['left', 'center', 'right'].includes(this.alignX || '')) {
      return 'flex-grow-0';
    }

    switch (width) {
      case 'content':
        return 'flex-grow-0';
      case 'auto':
        return 'w-full';
      case '1/2':
        return this.__collapsableWidth(`w-${width}`);
      case '1/3':
        return this.__collapsableWidth(`w-${width}`);
      case '2/3':
        return this.__collapsableWidth(`w-${width}`);
      case '1/4':
        return this.__collapsableWidth(`w-${width}`);
      case '3/4':
        return this.__collapsableWidth(`w-${width}`);
      case '1/5':
        return this.__collapsableWidth(`w-${width}`);
      case '2/5':
        return this.__collapsableWidth(`w-${width}`);
      case '3/5':
        return this.__collapsableWidth(`w-${width}`);
      case '4/5':
        return this.__collapsableWidth(`w-${width}`);
      default:
        return 'flex-grow-0';
    }
  }

  render() {
    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'space',
        'align-x',
        'align-y',
        'collapse-below',
        'columns-have-container',
      ]);

    return html`
      <div
        class=${this.__stylesController.tw(
          'flex',
          this.collapseBelow && `flex-col ${this.collapseBelow}:flex-row`,
          this.__computeSpaceX(),
          this.__computeAlignX(),
          this.__computeAlignY(),
        )}
        ...=${spread(attributesToSpread)}
      >
        ${Array.from(this.children).map((child, index) => {
          const columnWidth = this.columnsHaveContainer
            ? child.querySelector('mono-column')?.width
            : (child as unknown as MonoColumnComp).width;

          return html`
            <div
              class=${this.__stylesController.tw(
                this.__computeColumnWidth(columnWidth),
              )}
            >
              <slot
                name=${this.__uniqueSlotController.getSlotIdentifier(index)}
                @slotchange=${(event: Event) =>
                  this.__uniqueSlotController.onSlotChange(event)}
              ></slot>
            </div>
          `;
        })}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-columns': MonoColumnsComp;
  }
}
