import { LitElement, ReactiveController } from 'lit';

const isRunningInPercy = () =>
  typeof window === 'object' &&
  /proxyme\.percy\.io|render\.percy\.local/.test(window.location.hostname);

export class AnimationController implements ReactiveController {
  host: LitElement;

  shouldDisableAnimation: boolean = false;

  constructor(host: LitElement) {
    this.host = host;
    host.addController(this);
  }

  hostConnected() {
    this.shouldDisableAnimation = isRunningInPercy();
  }
}
