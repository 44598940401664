import { css, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { nothing, html } from 'lit/html.js';
import { createUUID } from './utils/UniqueId';
import { MonoTextComp } from './MonoTextComp';
import { fromOptionalConverter, spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { mask } from './utils/InputMask';
import { TailwindStylesController } from './utils/TailwindStylesController';
import { RoundedCorners } from './utils/CommonTypes';

export type InputMaskType =
  | 'number'
  | 'date'
  | 'phone'
  | 'currency'
  | 'currency-decimal';

export type InputMode =
  | 'none'
  | 'text'
  | 'decimal'
  | 'numeric'
  | 'tel'
  | 'search'
  | 'email'
  | 'url';

@customElement('mono-textfield')
export class MonoTextFieldComp extends LitElement {
  static styles = css`
    ::slotted(*:first-child) {
      margin: 0 !important;
    }
  `;

  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: String, reflect: true })
  value: string = '';

  @property({ type: String, reflect: true }) placeholder: string = '';

  @property({ type: String, reflect: true }) id: string = createUUID();

  @property({ type: String, reflect: true }) name: string = '';

  @property({ type: String, reflect: true, converter: fromOptionalConverter })
  error?: string;

  @property({ type: Boolean, reflect: true }) disabled: boolean = false;

  @property({ type: Boolean, reflect: true }) required: boolean = false;

  @property({ type: String, reflect: true, attribute: 'mask-type' })
  maskType?: InputMaskType;

  @property({ type: String, reflect: true, attribute: 'inputmode' })
  inputMode: InputMode = 'text';

  @property({ type: String, reflect: true }) corners: RoundedCorners = 'none';

  @query('input', true)
  __inputEl!: HTMLInputElement;

  @query('label', true)
  __labelEl!: HTMLLabelElement;

  @query('[id*="-errors"]', true)
  __errorEl!: MonoTextComp;

  __computMaskType() {
    switch (this.maskType) {
      case 'number':
        // kind of a hack, need to update input mask
        // but we aren't using it at the moment
        return '99999999999999999999999999999999999999999999999999999999999';
      case 'phone':
        return '9999999999';
      case 'date':
        return '99/99/9999';
      case 'currency':
        return this.__currencyFormatter(false);
      case 'currency-decimal':
        return this.__currencyFormatter(true);
      default:
        return null;
    }
  }

  private __currencyFormatter = (showDecimal: boolean) => (value: string) => {
    if (value === '') return value;

    const decimalIndex = value.indexOf('.');
    const hasDecimal = decimalIndex >= 0;

    let left = hasDecimal ? value.substring(0, decimalIndex) : value;
    left = left.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let symbol = left ? '$' : '';

    if (showDecimal && hasDecimal) {
      let right = value.substring(decimalIndex).replace(/\D/g, '');
      right = right.length > 2 ? right.substring(0, 2) : right; // Limit decimal to only 2 digits
      symbol = left || right ? '$' : '';
      return `${symbol}${left}.${right}`;
    }

    return `${symbol}${left}`;
  };

  private __onInput(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    const maskType = this.__computMaskType();
    const initialCaretPos = this.__inputEl.selectionStart || 0;
    if (maskType) {
      const newValue = mask(this.__inputEl.value, maskType);
      const newCaretPos =
        newValue.length - this.__inputEl.value.length + initialCaretPos;
      this.__inputEl.value = newValue;
      this.__inputEl.setSelectionRange(newCaretPos, newCaretPos); // put caret back in the right position
      this.value = newValue;
    } else {
      this.value = this.__inputEl.value;
    }

    const inputEvent = new CustomEvent('input', {
      detail: { value: this.value },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(inputEvent);
  }

  private __onChange(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    const maskType = this.__computMaskType();
    const initialCaretPos = this.__inputEl.selectionStart || 0;
    if (maskType) {
      const newValue = mask(this.__inputEl.value, maskType);
      const newCaretPos =
        newValue.length - this.__inputEl.value.length + initialCaretPos;
      this.__inputEl.value = newValue;
      this.__inputEl.setSelectionRange(newCaretPos, newCaretPos); // put caret back in the right position
      this.value = newValue;
    } else {
      this.value = this.__inputEl.value;
    }

    const changeEvent = new CustomEvent('change', {
      detail: { value: this.value },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(changeEvent);
  }

  private __hasError() {
    return this.error && this.error.length > 0;
  }

  private __renderError(ariaDescribedBy: string) {
    if (this.__hasError()) {
      return html`
        <mono-text as="p" size="sm" tone="alert" id=${ariaDescribedBy}>
          ${this.error}
        </mono-text>
      `;
    }

    return nothing;
  }

  render() {
    const ariaDescribedBy = this.__hasError() ? `${this.id}-errors` : '';

    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'value',
        'placeholder',
        'id',
        'name',
        'error',
        'disabled',
        'required',
        'mask-type',
        'aria-describedby',
        'corners',
      ]);

    return html`
      <div class=${this.__stylesController.tw('flex flex-col space-y-2.5')}>
        <div>
          <label for=${this.id}>
            <slot name="label"></slot>
          </label>
          <slot name="help"></slot>
        </div>
        <div>
          <input
            id=${this.id}
            name=${this.name}
            class=${this.__stylesController.tw(
              'appearance-none',
              'block w-full rounded-none border-2 border-neutral-4 px-2 py-2.5 transition duration-300',
              this.corners === 'rounded' && 'rounded',
              this.disabled && 'text-neutral-4 bg-neutral-2',
              !this.__hasError() &&
                !this.disabled &&
                'hover:(outline-none border-highlight-2) focus:(outline-none border-highlight-2)',
              this.__hasError() &&
                'border-opacity-0 outline-none ring(2 alert)',
            )}
            placeholder=${this.placeholder}
            .value=${this.value}
            ?disabled=${this.disabled}
            ?required=${this.required}
            aria-describedby=${ariaDescribedBy}
            @input=${this.__onInput}
            @change=${this.__onChange}
            ...=${spread(attributesToSpread)}
          />
        </div>
        ${this.__renderError(ariaDescribedBy)}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-textfield': MonoTextFieldComp;
  }
}
