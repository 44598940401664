import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { html } from 'lit/html.js';
import {
  TextAlign,
  FontWeigth,
  LetterSpacing,
  Tone,
} from './utils/CommonTypes';
import { FontFamily, FontSize } from './utils/TextUtils';
import { spread, fromOptionalConverter } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';

@customElement('mono-text-link')
export class MonoTextLinkComp extends LitElement {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({
    type: String,
    reflect: true,
  })
  href: string = '';

  @property({
    type: String,
    reflect: true,
  })
  tone: Tone = 'highlight-2';

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  size?: FontSize;

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  family?: FontFamily;

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  weight?: FontWeigth;

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  tracking?: LetterSpacing;

  @property({
    type: String,
    reflect: true,
    attribute: 'align-text',
    converter: fromOptionalConverter,
  })
  alignText?: TextAlign;

  @property({ type: Boolean, reflect: true }) inline: boolean = false;

  /* eslint-disable lit/binding-positions,lit/no-invalid-html  */

  render() {
    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'size',
        'family',
        'weight',
        'tracking',
        'tone',
        'align-text',
        'inline',
        'href',
      ]);

    return html`
      <a
        class=${this.__stylesController.tw(
          'underline',
          this.inline ? 'inline' : 'block',
          this.size && `text-${this.size}`,
          this.family && `font-${this.family}`,
          this.weight && `font-${this.weight}`,
          this.tracking && `tracking-${this.tracking}`,
          this.tone && `text-${this.tone}`,
          this.alignText && `text-${this.alignText}`,
        )}
        href=${this.href}
        ...=${spread(attributesToSpread)}
      >
        <slot></slot>
      </a>
    `;
  }

  /* eslint-enable lit/binding-positions,lit/no-invalid-html  */
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-text-link': MonoTextLinkComp;
  }
}
