import { css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeStatic, html } from 'lit/static-html.js';
import { ElementTagName, validElementTagNames } from './utils/HTMLTypes';
import {
  TextAlign,
  FontWeigth,
  LetterSpacing,
  Tone,
} from './utils/CommonTypes';
import { FontFamily, FontSize } from './utils/TextUtils';
import { spread, fromOptionalConverter } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';
import { assertTagNameIsAllowed } from './utils/AssertHelpers';

@customElement('mono-text')
export class MonoTextComp extends LitElement {
  static styles = css`
    ::slotted(*:first-child) {
      margin: 0 !important;
    }
  `;

  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: String, reflect: true }) as: ElementTagName = 'span';

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  size?: FontSize;

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  family?: FontFamily;

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  weight?: FontWeigth;

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  tracking?: LetterSpacing;

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  tone?: Tone;

  @property({
    type: String,
    reflect: true,
    attribute: 'align-text',
    converter: fromOptionalConverter,
  })
  alignText?: TextAlign;

  @property({ type: Boolean, reflect: true }) inline: boolean = false;

  @property({ type: Boolean, reflect: true }) truncate: boolean = false;

  /* eslint-disable lit/binding-positions,lit/no-invalid-html  */

  render() {
    assertTagNameIsAllowed(
      this.as,
      validElementTagNames as unknown as string[],
    );

    const tag = unsafeStatic(this.as);

    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'size',
        'family',
        'weight',
        'tracking',
        'tone',
        'align-text',
        'inline',
        'truncate',
      ]);

    return html`
      <${tag}
        class=${this.__stylesController.tw(
          this.inline ? 'inline' : 'block',
          this.size && `text-${this.size}`,
          this.family && `font-${this.family}`,
          this.weight && `font-${this.weight}`,
          this.tracking && `tracking-${this.tracking}`,
          this.tone && `text-${this.tone}`,
          this.alignText && `text-${this.alignText}`,
        )}
        ...=${spread(attributesToSpread)}
      >
      ${
        this.truncate
          ? html`
              <div class=${this.__stylesController.tw('truncate')}>
                <slot></slot>
              </div>
            `
          : html` <slot></slot> `
      }
      </${tag}>
    `;
  }

  /* eslint-enable lit/binding-positions,lit/no-invalid-html  */
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-text': MonoTextComp;
  }
}
