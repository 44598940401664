import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { html } from 'lit/html.js';
import { spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';

export type KeyValuePair = { key: string; value: string };

@customElement('mono-description-list')
export class MonoDescriptionListComp extends LitElement {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: Array, reflect: true }) values: KeyValuePair[] = [];

  render() {
    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'values',
      ]);

    return html`
      <dl
        ...=${spread(attributesToSpread)}
        class=${this.__stylesController.tw('space-y-7')}
      >
        ${this.values.map(
          ({ key, value }) => html`
            <div
              class=${this.__stylesController.tw(
                'sm:grid sm:grid-cols-3 sm:gap-4',
              )}
            >
              <dt>
                <mono-strong>${key}</mono-strong>
              </dt>
              <dd
                class=${this.__stylesController.tw(
                  'mt-5 sm:mt-0 sm:col-span-2',
                )}
              >
                <mono-text>${value}</mono-text>
              </dd>
            </div>
          `,
        )}
      </dl>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-description-list': MonoDescriptionListComp;
  }
}
