import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { html } from 'lit/html.js';
import { spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';

@customElement('mono-strong')
export class MonoStrongComp extends LitElement {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: Boolean, reflect: true }) inline: boolean = false;

  render() {
    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'inline',
      ]);

    return html`
      <strong
        class=${this.__stylesController.tw(this.inline ? 'inline' : 'block')}
        ...=${spread(attributesToSpread)}
      >
        <slot></slot>
      </strong>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-strong': MonoStrongComp;
  }
}
