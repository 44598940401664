import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { html } from 'lit/html.js';

export type ColumnWidth =
  | 'auto'
  | 'content'
  | '1/2'
  | '1/3'
  | '2/3'
  | '1/4'
  | '3/4'
  | '1/5'
  | '2/5'
  | '3/5'
  | '4/5';

@customElement('mono-column')
export class MonoColumnComp extends LitElement {
  @property({ type: String, reflect: true }) width: ColumnWidth = 'auto';

  render() {
    return html` <slot></slot> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-column': MonoColumnComp;
  }
}
