import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { html, nothing } from 'lit/html.js';
import { spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';

export type DividerStyle = 'none' | 'gradient';

@customElement('mono-card-layout')
export class MonoCardLayoutComp extends LitElement {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: String, reflect: true, attribute: 'background-image' })
  backgroundImage: string = '';

  @property({ type: String, reflect: true, attribute: 'divider-style' })
  dividerStyle: DividerStyle = 'gradient';

  private renderDivider() {
    switch (this.dividerStyle) {
      case 'gradient':
        return html`
          <mono-column width="content">
            <div
              class=${this.__stylesController.tw(
                'h-10 w-full bg-gradient-to-b from-gray-100 lg:(h-full w-10 bg-gradient-to-r from-gray-100)',
              )}
            ></div>
          </mono-column>
        `;
      default:
        return nothing;
    }
  }

  render() {
    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
      ]);

    const backgroundStyle = `background-image:url('${this.backgroundImage}');`;

    return html`
      <div
        class=${this.__stylesController.tw(
          'flex bg-fixed bg-cover bg-center sm:(justify-center items-center py-9) lg:py-20',
        )}
        style=${backgroundStyle}
        ...=${spread(attributesToSpread)}
      >
        <div
          class=${this.__stylesController.tw(
            'w-full sm:(px-3 max-w-screen-sm) md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl',
          )}
        >
          <mono-card rounded-above="sm">
            <mono-columns collapse-below="lg">
              <mono-column>
                <slot></slot>
              </mono-column>
              ${this.renderDivider()}
              <mono-column width="content">
                <slot name="secondary"></slot>
              </mono-column>
            </mono-columns>
          </mono-card>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-card-layout': MonoCardLayoutComp;
  }
}
