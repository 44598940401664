import { PropertyValues, css, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { html } from 'lit/html.js';
import tippy, {
  Instance as Tippy,
  roundArrow,
} from '@medibank-digital/tippy.js';
import { spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';

@customElement('mono-help-tooltip')
export class MonoHelpTooltipComp extends LitElement {
  static styles = [
    css`
      .tippy-box[data-placement^='top'] > .tippy-svg-arrow {
        bottom: 0;
      }
      .tippy-box[data-placement^='top'] > .tippy-svg-arrow:after,
      .tippy-box[data-placement^='top'] > .tippy-svg-arrow > svg {
        top: 16px;
        transform: rotate(180deg);
      }
      .tippy-box[data-placement^='bottom'] > .tippy-svg-arrow {
        top: 0;
      }
      .tippy-box[data-placement^='bottom'] > .tippy-svg-arrow > svg {
        bottom: 16px;
      }
      .tippy-box[data-placement^='left'] > .tippy-svg-arrow {
        right: 0;
      }
      .tippy-box[data-placement^='left'] > .tippy-svg-arrow:after,
      .tippy-box[data-placement^='left'] > .tippy-svg-arrow > svg {
        transform: rotate(90deg);
        top: calc(50% - 3px);
        left: 11px;
      }
      .tippy-box[data-placement^='right'] > .tippy-svg-arrow {
        left: 0;
      }
      .tippy-box[data-placement^='right'] > .tippy-svg-arrow:after,
      .tippy-box[data-placement^='right'] > .tippy-svg-arrow > svg {
        transform: rotate(-90deg);
        top: calc(50% - 3px);
        right: 11px;
      }
      .tippy-svg-arrow {
        width: 16px;
        height: 16px;
        fill: #333;
        text-align: initial;
      }
      .tippy-svg-arrow,
      .tippy-svg-arrow > svg {
        position: absolute;
      }

      .tippy-box[data-theme~='medibank'] > .tippy-svg-arrow {
        fill: #d8dbe3;
      }

      ::slotted(*:first-child) {
        margin: 0 !important;
      }
    `,
  ];

  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: Boolean, reflect: true })
  show: boolean = false;

  @query('#target', true)
  __target!: HTMLButtonElement;

  @query('#tooltip', true)
  __tooltip!: HTMLElement;

  __tippy?: Tippy;

  firstUpdated(changedProperties: PropertyValues<this>) {
    super.firstUpdated(changedProperties);

    this.__tippy = tippy(this.__target, {
      arrow: roundArrow,
      theme: 'medibank',
      content: this.__tooltip,
      interactive: true,
      appendTo: 'parent',
      animation: false,
      trigger: 'mouseenter focus click',
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 7],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              padding: 8,
            },
          },
        ],
      },
      onShow: (_instance) => {
        this.show = true;
      },
      onHide: (_instance) => {
        this.show = false;
      },
    });

    if (this.show) {
      this.__tippy?.show();
    }
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();

    this.__tippy?.destroy();
  }

  render() {
    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'id',
        'aria-describedby',
        'show',
      ]);

    return html`
      <div
        class=${this.__stylesController.tw('inline-flex h-4 w-4 align-middle')}
      >
        <button
          id="target"
          class=${this.__stylesController.tw(
            'inline-flex focus:outline-none focus-visible:outline-black',
          )}
          ..=${spread(attributesToSpread)}
        >
          <mono-icon
            icon-name="help-bubble"
            size="3.5"
            aria-label="help icon"
          ></mono-icon>
        </button>
        <div
          id="tooltip"
          role="tooltip"
          class=${this.__stylesController.tw(
            'bg-white max-w-tooltip border-2 border-neutral-4 items-center justify-center px-6 py-4',
          )}
        >
          <slot></slot>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-help-tooltip': MonoHelpTooltipComp;
  }
}
