import { css, LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { unsafeStatic, html } from 'lit/static-html.js';
import { ElementTagName, validElementTagNames } from './utils/HTMLTypes';
import { ResponsiveBreakpoint } from './utils/CommonTypes';
import { Hideable } from './utils/CommonInterfaces';
import { spread, fromOptionalConverter } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';
import { assertTagNameIsAllowed } from './utils/AssertHelpers';

@customElement('mono-hidden')
export class MonoHiddenComp extends LitElement implements Hideable {
  static styles = css`
    ::slotted(*:first-child) {
      margin: 0 !important;
    }
  `;

  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  @property({ type: String, reflect: true }) as: ElementTagName = 'div';

  @property({ type: Boolean, reflect: true }) inline: boolean = false;

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  above?: ResponsiveBreakpoint;

  @property({
    type: String,
    reflect: true,
    converter: fromOptionalConverter,
  })
  below?: ResponsiveBreakpoint;

  @query('div', true)
  __rootEl!: HTMLElement;

  isHidden() {
    return (
      window.getComputedStyle(this.__rootEl).getPropertyValue('display') ===
      'none'
    );
  }

  /* eslint-disable lit/binding-positions,lit/no-invalid-html  */

  render() {
    assertTagNameIsAllowed(
      this.as,
      validElementTagNames as unknown as string[],
    );

    const tag = unsafeStatic(this.as);

    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'inline',
        'above',
        'below',
      ]);

    const display = this.inline ? 'inline' : 'flex';

    return html`
      <${tag}
        class=${this.__stylesController.tw(
          'hidden',
          this.above && `${display} ${this.above}:hidden`,
          this.below && `${this.below}:${display}`,
        )}
        ...=${spread(attributesToSpread)}
      >
        <slot></slot>
      </${tag}>
    `;
  }

  /* eslint-enable lit/binding-positions,lit/no-invalid-html  */
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-hidden': MonoHiddenComp;
  }
}
