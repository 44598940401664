import { LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeStatic, html } from 'lit/static-html.js';
import { assertTagNameIsAllowed } from './utils/AssertHelpers';
import { ResponsiveBreakpoint } from './utils/CommonTypes';
import { fromOptionalConverter, spread } from './utils/LitHelper';
import { SpreadController } from './utils/SpreadController';
import { TailwindStylesController } from './utils/TailwindStylesController';
import { UniqueSlotController } from './utils/UniqueSlotController';

export const validCardElementTagNames = [
  'article',
  'aside',
  'details',
  'div',
  'main',
  'section',
] as const;

export type CardElementTagName = typeof validCardElementTagNames[number];

@customElement('mono-card')
export class MonoCardComp extends LitElement {
  private __spreadController: SpreadController = new SpreadController(this);

  private __stylesController: TailwindStylesController =
    new TailwindStylesController(this);

  private __uniqueSlotController: UniqueSlotController =
    new UniqueSlotController(this);

  @property({ type: String, reflect: true }) as: CardElementTagName = 'div';

  @property({ type: Boolean, reflect: true }) rounded: boolean = false;

  @property({
    type: String,
    reflect: true,
    attribute: 'rounded-above',
    converter: fromOptionalConverter,
  })
  roundedAbove?: ResponsiveBreakpoint;

  /* eslint-disable lit/binding-positions,lit/no-invalid-html  */

  render() {
    assertTagNameIsAllowed(
      this.as,
      validCardElementTagNames as unknown as string[],
    );

    const tag = unsafeStatic(this.as);

    const attributesToSpread =
      this.__spreadController.buildSpreadAttributesIgnoring([
        'as',
        'style',
        'class',
        'slot',
        'rounded',
        'rounded-above',
      ]);

    return html`
      <${tag}
        class=${this.__stylesController.tw(
          'flex flex-col bg-white',
          this.rounded && 'overflow-hidden rounded-2xl shadow-2xl',
          this.roundedAbove &&
            `${this.roundedAbove}:(overflow-hidden rounded-2xl shadow-2xl)`,
        )}
        ...=${spread(attributesToSpread)}
      >
        ${Array.from(this.children).map(
          (_, index) =>
            html` <div>
              <slot
                name=${this.__uniqueSlotController.getSlotIdentifier(index)}
                @slotchange=${(event: Event) =>
                  this.__uniqueSlotController.onSlotChange(event)}
              ></slot>
            </div>`,
        )}
      </${tag}>
    `;
  }

  /* eslint-enable lit/binding-positions,lit/no-invalid-html  */
}

declare global {
  interface HTMLElementTagNameMap {
    'mono-card': MonoCardComp;
  }
}
